import axios from '@axios'
import router from '@/router'
import User from '@/store/Models/users/User'
const bcrypt = require('bcryptjs')
const jwt = require('jsonwebtoken')
import store from './../index'
import LocalUser from '@/store/Models/users/LocalUser'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {
  },
  actions: {

    signInUser (ctx, queryParams) {
      if(store.state.serverStatus) {
        return ctx.dispatch('signIn', queryParams)
      }
      else {
        return ctx.dispatch('signInLocal', queryParams)
      }
    },

    signIn(ctx, queryParams) {
      return new Promise(async (resolve, reject) => {
        axios
          .post('auth/signIn', queryParams)
          .then((response) => {
            if (response.status === 200) {
              ctx.commit('SET_ACCESS_TOKEN', response.data.accessToken, {root: true})
              ctx.commit('SET_USER_TYPE', response.data.type, {root: true})
              ctx.commit('SET_USER', response.data.user, {root: true})
              ctx.commit('SET_USER_PAGES', response.data.pages, {root: true})
              ctx.commit('SET_USER_PERMISSIONS', response.data.permissions, {root: true})
              ctx.dispatch('updateDB', null, {root: true})
                .then(() => {
                  router.push('/' + response.data.pages[0].route)
                })
                  .catch(() => {
                    router.push('/' + response.data.pages[0].route)
                  })
            }
            else if (response.status === 203) {
              ctx.commit('OPEN_CODE_VERIFICATION', null, {root: true})
              ctx.commit('SET_VERIFICATION_MESSAGE', response.data.message, {root: true})
            }

            resolve(response.data)
          })
          .catch((error) => {
            if (!error.response) {
              ctx.commit('SET_SERVER_STATUS', false, {root: true})
              ctx.dispatch('signInLocal', queryParams)
                .then(() => {
                  resolve(true)
                })
            } else {
              reject(error)
            }
          })
      })
    },

    userVerification (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios.post('auth/userVerification', payload)
          .then((response) => {
            if (response.status === 200) {
              ctx.commit('SET_ACCESS_TOKEN', response.data.accessToken, {root: true})
              ctx.commit('CLOSE_CODE_VERIFICATION', null, {root: true})
              ctx.commit('verificationMessage', '', {root: true})
              ctx.commit('SET_USER_TYPE', response.data.type, {root: true})
              ctx.commit('SET_USER', response.data.user, {root: true})
              ctx.commit('SET_USER_PAGES', response.data.pages, {root: true})
              ctx.commit('SET_USER_PERMISSIONS', response.data.permissions, {root: true})
              ctx.dispatch('updateDB', null, {root: true})
                .then(() => {
                  router.push('/' + response.data.pages[0].route)
                })
            }
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    signInLocal (ctx, queryParams) {
      return new Promise(async (resolve, reject) => {
        try {
          const now = new Date()
          now.toLocaleString('AM', { timeZone: 'Asia/Yerevan' })
          let hour = now.getHours()
          if (hour >= 19 || hour < 9) {
            const data = {
              response: {
                data: {message: 'Մուտքն արգելված է։'}
              }
            }
            reject(data)
            return false
          }
          if (store.state.time && typeof store.state.time.diff !== "undefined" && store.state.time.diff) {
            if (store.state.time.diff > 0) {
              const data = {
                response: {
                  data: {message: 'Մուտքն արգելված է։'}
                }
              }
              reject(data)
              return false
            }
          }
          const model = new LocalUser()
          const user = await model.signIn(queryParams.email, queryParams.password)
          if (typeof user !== "undefined" && user !== "undefined" && user !== null) {
            const isPasswordMatch = await bcrypt.compare(queryParams.password, user.password)
            if (isPasswordMatch) {
              const token = jwt.sign({_id: user._id}, process.env.VUE_APP_JWT_KEY)
              const userData = {
                id: user._id,
                name: user.name,
                email: user.email
              }
              console.log(user)
              ctx.commit('SET_ACCESS_TOKEN', token, {root: true})
              ctx.commit('SET_USER_TYPE', user.type, {root: true})
              ctx.commit('SET_USER', userData, {root: true})
              ctx.commit('SET_USER_PAGES_LOCAL', user.pages, {root: true})
              ctx.commit('SET_USER_PERMISSIONS', user.permissions, {root: true})
              router.push('/' + user.pages[0].slug)
              resolve(user)
            }
            else {
              const data = {
                response: {
                  data: {message: 'Սխալ մուտքանուն կամ գաղտնաբառ։'}
                }
              }
              reject(data)
            }
          }
          else {
            const data = {
              response: {
                data: {message: 'Տվյալների բազան կառուցված չէ։ Անհրաժեշտ է ինտերնետ կապ։'}
              }
            }
            reject(data)
          }
        }
        catch (error)  {
          console.log('catch')
          console.log(error)
          reject(error)
        }

      })
    },

    signOut (ctx) {
      if(store.state.serverStatus) {
        return ctx.dispatch('logOut')
      }
      else {
        return ctx.dispatch('logOutLocal')
      }
    },

    logOut (ctx) {
      return new Promise(((resolve, reject) => {
        axios.post('auth/signOut')
          .then((response) => {
            ctx.commit('SIGN_OUT', null, {root: true})
            router.push('/login')
            resolve(response)
          })
          .catch((error) => {
            if (!error.response) {
              ctx.commit('SET_SERVER_STATUS', false, {root: true})
              ctx.dispatch('logOutLocal')
                .then(() => {
                  resolve(true)
                })
            } else {
              reject(error)
            }
          })
      }))
    },

    logOutLocal (ctx) {
      return new Promise((resolve) => {
        ctx.commit('SIGN_OUT', null, {root: true})
        router.push('/login')
        resolve(true)
      })
    },

    changePassword(ctx, payload) {
      return new Promise(((resolve, reject) => {
        axios.post('auth/change-password', payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    checkTechService ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`tech-service-check-tech-service/30bea56759717630b026f95acce0b27608bb2eaa9ec4a1d1157258924147931eSCK`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    addNotification ({}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`checkup-notifications`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getTaxPaymentUrl (ctx, code) {
      return new Promise((resolve, reject) => {
        axios.post(`tech-service-get-tax-payment`, {code: code})
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
  },
}
