export default [
  {
    title: 'Home',
    route: 'home',
  },
  {
    title: 'Second Page',
    route: 'second-page',
  },
]
