import axios from '@axios'

let todayStart = new Date()
todayStart.setHours(0, 0, 0, 0)

let todayEnd = new Date()
todayEnd.setHours(23, 59, 59, 59)

export default {
    namespaced: true,
    state: {
        companies: [],
        info: null,
        dateRange: {
            startDate: todayStart,
            endDate: todayEnd,
        },
    },

    mutations: {
        SET_COMPANIES (state, data) {
            state.companies = data;
        },
        SET_INFO (state, data) {
            state.info = data;
        },
        UPDATE_DATE_RANGES(state, data) {
            state.dateRange.startDate = data.startDate
            state.dateRange.endDate = data.endDate
        },
    },
    actions: {
        getCompanies({ commit }, {type, query}) {
            return new Promise((resolve, reject) => {
                let url = type === 'ADMIN' ? 'companies' : 'viewer-companies'
                url += `?${query}`
                axios.get(url)
                    .then((response) => {
                        commit('SET_COMPANIES', response.data.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        getInfo ({ commit }, { type, id, query }) {
            return new Promise((resolve, reject) => {
                let url = type === 'ADMIN' ? 'analysis' : 'viewer-analysis'
                url += `/${id}?${query}`
                axios.get(url)
                    .then((response) => {
                        commit('SET_INFO', response.data.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }
}
