<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Ծանուցումներ
        </h4>
        <b-button
            @click="openNewNotificationBox"
            variant="primary"
            size="sm"
        >
          Նոր հաղորդագրություն
        </b-button>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar v-if="notifications.length > 0"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <div
        v-for="notification in notifications">
        <b-media>
          <div class="mr-2" style="width: 20%">{{ notification.companyName }}</div>
          <div style="width: 55%" class="media-heading">
            <p style="cursor: pointer" @click="openNotification(notification)">
              {{ notification.title }}
            </p>
            <p class="notification-text d-flex w-100">{{ notification.message }}</p>
          </div>
          <div style="width: 10%">
            {{ notification.extraDate }}
          </div>
          <div style="text-align: center; width: 15%">
            <p>
              {{notification.createdAt | formatDate }}
            </p>
            <p>
              <fa-icon class="cursor-pointer" @click="readNotificationBy(notification)" icon="trash-alt"/>
            </p>
          </div>
        </b-media>
      </div>

    </vue-perfect-scrollbar>
    <li v-else class="p-2 text-center">
      Դուք չունեք նոր ծանուցումներ
    </li>

    <b-modal
        id="notification-modal"
        modal-class="modal-primary"
        centered
        :title="modal_title"
    >
      <b-card-header class="mb-1 p-1">
        <div class="d-flex justify-content-between">
          <div>{{notification_from}}</div>
          <div>{{notification_time}}</div>
        </div>
      </b-card-header>
      <b-card-text>
        {{notification_content}}
      </b-card-text>


      <template #modal-footer="{ ok, cancel, hide }">

        <template v-if="notification && notification.notificationType === 'ASK_TO_EDIT'">
          <b-button @click="editCheckup" size="sm" variant="success">
            Խմբագրել
          </b-button>
        </template>


        <b-button v-if="notification && notification.notificationType === 'ASK_TO_EDIT'" @click="readNotification" size="sm" variant="info">
          Մերժել
        </b-button>
        <b-button v-if="notification && notification.notificationType === 'ASK_TO_EDIT'" @click="removeCheckup" size="sm" variant="danger">
          Հեռացնել
        </b-button>
        <b-button v-else @click="readNotification" size="sm" variant="info">
          Նշել որպես կարդացված
        </b-button>
      </template>
    </b-modal>

    <b-modal
        :no-close-on-backdrop="true"
        id="new-notification-admin"
        modal-class="modal-primary"
        centered
        title="Նոր հաղորդագրություն"
    >

      <b-form>

        <b-form-group>
          <div v-for="userType of userTypes">
            <b-form-radio
                @change="changeUserType"
                plain
                v-model="newMessage.selectedUserType"
                name="some-radios3"
                :value="userType.value"
            >
              {{userType.label}}
            </b-form-radio>
          </div>

        </b-form-group>

        <b-form-group v-if="newMessage.selectedUserType === 'COMPANY'">
          <v-select
              placeholder="Կազմակերպություն"
              id="vue-select"
              :reduce="prefix => prefix.id"
              v-model="newMessage.company"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="companies"
              class="companyFilterSelect"
              :clearable="false"
          />
        </b-form-group>

        <b-form-group
            v-if="newMessage.selectedUserType !== 'VIEWER'"
            label="Ընտրել"
            label-for="user-pages"
        >
          <v-select
              v-model="newMessage.userTypes"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="userTypesList"
              :reduce="prefix => prefix.id"
              multiple
          />
        </b-form-group>

        <b-form-group>
          <b-form-textarea
              id="textarea-admin"
              v-model="newMessage.message"
              placeholder="Հաղորդագրություն"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-form>

      <template #modal-footer="{ ok, cancel, hide }">

        <b-button :disabled="adminFormValidation" @click="sendNewNotification" size="sm" variant="info">
          Ուղարկել
        </b-button>
      </template>

    </b-modal>

    <b-modal
        id="new-notification-user"
        modal-class="modal-primary"
        centered
        title="Նոր հաղորդագրություն"
    >

      <b-form>

        <b-form-group v-if="userEmail === 'grigoryansasun68@gmail.com'">
          <div v-for="userType of userTypes">
            <b-form-radio
                @change="changeUserType"
                plain
                v-model="newMessage.selectedUserType"
                name="some-radios3"
                :value="userType.value"
            >
              {{userType.label}}
            </b-form-radio>
          </div>

        </b-form-group>

        <b-form-group>
          <b-form-textarea
              id="textarea"
              v-model="newMessage.message"
              placeholder="Հաղորդագրություն"
              rows="3"
              max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-form>

      <template #modal-footer="{ ok, cancel, hide }">

        <b-button :disabled="newMessage.message === ''" @click="sendNewNotification" size="sm" variant="info">
          Ուղարկել
        </b-button>
      </template>

    </b-modal>

    <!-- Cart Footer -->
<!--    <li class="dropdown-menu-footer"><b-button-->
<!--      v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
<!--      variant="primary"-->
<!--      block-->
<!--    >Read all notifications</b-button>-->
<!--    </li>-->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BModal, BCardText, BCardHeader, BFormGroup, BFormTextarea, BForm, BFormRadio
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'
import companyModule from '@/store/companies/companyModule'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BModal,
    BCardText,
    BCardHeader,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BForm,
    BFormRadio
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      newMessage: {
        notificationType: 'NEW_MESSAGE',
        title: 'Նոր հաղորդագրություն',
        message: '',
        company: null,
        selectedUserType: 'ALL',
        userTypes: []
      },
      userTypesList: [
        {
          label: 'Սեփականատերերին',
          id: 'TECHNICAL_SERVICE_OWNER',
        },
        {
          label: 'Օպերատորներին',
          id: 'TECHNICAL_SERVICE_OPERATOR'
        },
        {
          label: 'Հսկիչներին',
          id: 'TECHNICAL_SERVICE_MECHANIC'
        }
      ],
      userTypes: [
        {
          label: 'Բոլորին',
          value: 'ALL'
        },
        {
          label: 'Կազմակերպությանը',
          value: 'COMPANY'
        },
        {
          label: 'Դիտորդներին',
          value: 'VIEWER'
        }
      ]
    }
  },
  computed: {

    notification: {
      get () {
        return this.$store.state.notification
      },
      set (data) {
        this.$store.commit('SET_NOTIFICATION', data)
      }
    },

    adminFormValidation () {
      if (this.newMessage.selectedUserType === 'ALL') {
        return this.newMessage.message === '' || this.newMessage.userTypes.length === 0
      }
      else if (this.newMessage.selectedUserType === 'COMPANY') {
        return this.newMessage.message === '' || this.newMessage.userTypes.length === 0 || !this.newMessage.company
      }
      else {
        return this.newMessage.message === ''
      }
    },

    companies() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
        label: e.name,
        id: e._id
      }))
    },

    userType () {
      return atob(this.$store.state.user_type)
    },

    userEmail () {
      return this.$store.state.user.email;
    },

    notifications () {
      return this.$store.state.notifications.map(e => ({
        avatar: e.from ? this.nameLetters(e.from.name) : '',
        type: 'light-info', ...e
      }))
    },
    nameLetters () {
      return (name) => {
        const fullName = name.split(' ');
        let initials = ''
        let firstName = fullName.shift()
        let lastName = fullName.pop()
        if (typeof firstName === 'string') {
          initials += firstName.charAt(0)
        }
        if (typeof lastName === 'string') {
          initials += lastName.charAt(0)
        }
        return initials;
      }
    },

    modal_title () {
      return (this.notification) ? this.notification.title : ''
    },
    notification_content () {
      return (this.notification) ? this.notification.message : ''
    },

    notification_from () {
      if (this.notification) {
        let name = this.notification.from.name
        if (this.notification.from.company) {
          name += ' (' + this.notification.from.company.name + ')'
        }
        else if (this.notification.from.companies.length > 0) {
          name += ' (' + this.notification.from.companies[0].name + ')'
        }
        return name
      }
      else {
        return ''
      }
    },

    notification_time () {
      return (this.notification) ? this.$options.filters.formatDate(this.notification.createdAt) : ''
    }
  },
  setup() {

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },

  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    this.getCompanies()
  },

  methods: {

    getCompanies() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
    },

    openNotification (notification) {
      this.notification = notification
      this.$bvModal.show('notification-modal')
    },

    readNotification () {
      if (this.notification) {
        this.$store.dispatch('readNotification', {_id: this.notification._id, status: true})
      }
      this.$bvModal.hide('notification-modal')
    },

    readNotificationBy (notification) {
      this.$store.dispatch('readNotification', {_id: notification._id, status: true})
      this.$bvModal.hide('notification-modal')
    },

    removeCheckup() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/editAdminCheckup', {
        _id: this.notification.data.id,
        deleted: true
      })
      .then(() => {
        this.$bvModal.hide('notification-modal')
        this.$store.dispatch('readNotification', {
          _id: this.notification._id,
          status: true
        })
            .then(() => {
              const payload = {
                notificationType: 'NOTIFICATION',
                title: 'Հեռացնամ հայտի հաստատում',
                to: this.notification.from._id,
                message: 'փոփոխությունը կատարված է։',
                data: {
                  id: this.notification.data.id,
                  collection: 'checkups'
                },
              }
              this.$store.dispatch('addNotification', payload)
                  .then((response) => {
                    response.data.map((notification) => {
                      // this.$socket.emit('SEND_NOTIFICATION', notification)
                    })
                  })
            })
      })
    },

    editCheckup () {
      if (this.notification) {
        this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupForEdit', this.notification.data.id)
        .then((response) => {
          if (response.data.item) {
            this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_EDIT_FORM', response.data.item)
            this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_ASK_TO_EDIT', this.notification)
            if (this.$route.name !== 'tech-service-view') {
              this.$router.push({name: 'tech-service-view'})
            }

          }

        })
      }
      this.$bvModal.hide('notification-modal')
    },

    openNewNotificationBox () {
      if (this.userType === 'ADMIN') {
        this.$bvModal.show('new-notification-admin')
      }
      else {
        this.$bvModal.show('new-notification-user')
      }
    },

    changeUserType () {
      console.log(this.newMessage.selectedUserType)
    },

    sendNewNotification () {
      this.$store.dispatch('addNotification', this.newMessage)
          .then((response) => {
            this.$bvModal.hide('new-notification-user')
            this.$bvModal.hide('new-notification-admin')
            this.$swal({
              title: '',
              text: 'Հաղորդագրությունն ուղարկվել է!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.newMessage = {
              notificationType: 'NEW_MESSAGE',
              title: 'Նոր հաղորդագրություն',
              message: '',
              company: null,
              selectedUserType: 'ALL',
              userTypes: []
            }
            response.data.map((notification) => {
              // this.$socket.emit('SEND_NOTIFICATION', notification)
            })
          })
    }
  }
}
</script>

<style>
.dropdown-menu-media {
  width: 60rem!important;
}
.media-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 0.8rem;
}
.media-body p {
  color: #000!important;
  font-weight: bold;
}
</style>
