<template>
  <ul>
    <component
        v-if="badgeCheck"
        :is="resolveNavItemComponent(item)"
        v-for="item in items"
        :key="item.header || item.title"
        :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import api from '@/router/routes/api'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  data() {
    return {
      badgeCheck: true
    }
  },
  created() {
    const apiIndex = this.items.findIndex(r => r.route === 'api')
    if (apiIndex >= 0) {
      if (this.$checkPermission('api-history', 'view')) {
        this.badgeCheck = false
        this.checkNewLogs(apiIndex)
      }
    }
  },
  methods: {
    checkNewLogs(index) {
      this.$store.dispatch('checkNewApiLogs')
          .then(response => {
            this.$store.commit("UPDATE_MENU_BADGE", {index, count: response.count, variant: 'info'})
            this.badgeCheck = true
          })
    }
  }
}
</script>
